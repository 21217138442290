<template>
  <div>
    <div class="container" style="font-size: 1.5625vw;font-weight: 500;">产品说明书</div>
    <div class="top">
      <p>
        <u><a href="">Y02B产品说明书</a></u>
      </p>
      <p>
        <u><a href="">Y03B产品说明书</a></u>
      </p>
      <p>
        <u><a href="">Y02L产品说明书</a></u>
      </p>
      <p>
        <u><a href="">Y03L产品说明书</a></u>
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
  margin: 4rem 0;
  text-align: center;
  .top {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    p {
      width: 50%;
      text-align: center;
      margin: 1.025rem 0;
      font-size: 1.3542vw;
    }
  }
}
</style>
